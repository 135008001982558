body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto';
	text-decoration: none !important;
}

a,
u {
	text-decoration: none !important;
}
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}

input:focus {
	outline: none;
}
